<template>
  <div class="page-container">
    <!-- 顶部导航 -->
    <nav class="nav-bar">
      <div class="nav-content">
      <div class="logo">
        <img src="../assets/vpn/logo.png" alt="logo" width="40" />
        <span>FlashGo</span>
      </div>
        <div class="nav-right">
          <div class="store-buttons">
          <img src="../assets/vpn/ios.png" alt="App Store" @click="handleDownload('ios')" />
          <img src="../assets/vpn/an.png" alt="Google Play" @click="handleDownload('android')" />
          <img src="../assets/vpn/win.png" alt="Windows" @click="handleDownload('windows')" />
          <img src="../assets/vpn/mac.png" alt="macOS" @click="handleDownload('macos')" />
        </div>
      </div>
      </div>
    </nav>

    <!-- 主内容区域 -->
    <main class="main-content">
      <!-- Hero区域 -->
      <section class="hero-section">
        <h1>懂你所需 畅连全球<br/>高速稳定 无限访问</h1>
        <p class="subtitle">超多节点可用 延时低至10ms</p>
        <div class="download-options">
          <div class="store-buttons">

            <div class="download-item">
              <div class="download-btn play-store-btn" @click="handleDownload('android')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'android']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">Android APK</div>
                </div>
              </div>
              <div class="tip-text">无需安装 Google Play</div>
            </div>

            <div class="download-item">
              <div class="download-btn windows-btn" @click="handleDownload('windows')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'windows']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">Windows</div>
                </div>
              </div>
              <div class="tip-text">需要以管理员身份运行</div>
            </div>

            <div class="download-item">
              <div class="download-btn macos-btn" @click="handleDownload('macos')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'apple']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">macOS</div>
                </div>
              </div>
              <div class="tip-text">无需苹果账户</div>
            </div>

            <div class="download-item">
              <div class="download-btn app-store-btn" @click="handleDownload('ios')">
                <div class="icon">
                  <font-awesome-icon :icon="['fab', 'apple']" />
                </div>
                <div class="text-container">
                  <div class="small-text">立即下载</div>
                  <div class="large-text">App Store</div>
                </div>
              </div>
              <div class="tip-text">商店上架中，敬请期待</div>
            </div>

          </div>
        </div>
      </section>

      <!-- 页脚 -->
      <footer class="footer">
        <div class="footer-content">
          <div class="footer-logo">
            <img src="../assets/vpn/logo.png" alt="logo" width="30" />
            <span>Brought to you by FlashGo</span>
        </div>
          <div class="store-buttons">
          <img src="../assets/vpn/ios.png" alt="App Store" @click="handleDownload('ios')" />
          <img src="../assets/vpn/an.png" alt="Google Play" @click="handleDownload('android')" />
          <img src="../assets/vpn/win.png" alt="Windows" @click="handleDownload('windows')" />
          <img src="../assets/vpn/mac.png" alt="macOS" @click="handleDownload('macos')" />
        </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
export default {
  name: 'HomeIndex',
  data() {
    return {
      windowWidth: window.innerWidth,
      downloadLinks: {
        windows: 'https://marketing.ptrkz.cn:444/client/FlashGo_latest.exe',
        macos: 'https://marketing.ptrkz.cn:444/client/FlashGo_latest.dmg',
        android: 'https://marketing.ptrkz.cn:444/client/FlashGo_latest.apk',
        ios: 'https://marketing.ptrkz.cn:444/client/FlashGo_latest.ipa'
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    handleDownload(platform) {
      const link = this.downloadLinks[platform]
      if (platform === 'ios') {
        // iOS 跳转到 App Store
        window.open(link, '_blank')
      } else {
        // 其他平台直接下载
        const a = document.createElement('a')
        a.href = link
        a.download = `FlashGo_latest${platform === 'windows' ? '.exe' : platform === 'macos' ? '.dmg' : '.apk'}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  min-height: 100vh;
  background: #000;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 70px;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);

  .nav-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        font-size: 24px;
        font-weight: bold;
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 400%;
        animation: flow 20s linear infinite;
      }
    }

    .nav-right {
      .store-buttons {
        display: flex;
        gap: 16px;

        img {
          height: 36px;
          cursor: pointer;
          transition: transform 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.main-content {
  padding-top: 70px;
}

.hero-section {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 120px 20px 40px;
  background: radial-gradient(circle at center, #1a1a1a 0%, #000 100%);

  h1 {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 8px;
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    animation: flow 20s linear infinite;
  }

  .subtitle {
    font-size: 24px;
    color: #888;
    margin-bottom: 16px;
  }

  .download-options {
    margin-top: 24px;

    .store-buttons {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      .download-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .download-btn {
          height: 56px;
          padding: 0 20px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          transition: all 0.2s;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: white;
          font-size: 16px;
          text-decoration: none;
          min-width: 150px;

          .icon {
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .small-text {
              font-size: 12px;
              opacity: 0.8;
            }

            .large-text {
              font-size: 15px;
              font-weight: 500;
            }
          }

          &:hover {
            background: rgba(255, 255, 255, 0.15);
            transform: translateY(-2px);
            border-color: rgba(255, 255, 255, 0.3);
          }

          &.app-store-btn {
            background: linear-gradient(45deg, #1a73e8, #174ea6);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #1557b0, #1a73e8);
            }
          }

          &.play-store-btn {
            background: linear-gradient(45deg, #00c853, #009624);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #00e676, #00c853);
            }
          }

          &.windows-btn {
            background: linear-gradient(45deg, #4f0d95, #452296);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #5a0fb0, #4d27aa);
            }
          }

          &.macos-btn {
            background: linear-gradient(45deg, #4f0d95, #452296);
            border: none;

            &:hover {
              background: linear-gradient(45deg, #5a0fb0, #4d27aa);
            }
          }
        }

        .tip-text {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
          margin-top: 4px;
        }
      }
    }
  }
}

.footer {
  padding: 60px 20px;
  background: #000;

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .footer-logo {
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        font-size: 20px;
        color: #888;
      }
    }

    .store-buttons {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      img {
        height: 48px;
        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media (max-width: 768px) {
  .nav-bar {
    height: 60px;

    .nav-content {
      .logo span {
        font-size: 20px;
      }

      .store-buttons img {
        height: 32px;
      }
    }
  }

  .main-content {
    padding-top: 60px;
  }

  .hero-section {
    min-height: calc(100vh - 60px);
    padding: 40px 20px 20px;

    h1 {
      font-size: 32px;
    }

    .subtitle {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .download-options {
      margin-top: 16px;
    }

    .download-options .store-buttons img {
      height: 40px;
    }
  }

  .footer {
    padding: 40px 20px;

    .footer-content {
      gap: 24px;

      .store-buttons img {
        height: 40px;
      }
    }
  }
}

/* 平板设备适配 */
@media (min-width: 768px) and (max-width: 1024px) {
  .hero-section {
    .download-options {
      width: 100%;
      padding: 0 20px;

      .store-buttons {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 24px;

        .download-item {
          width: 100%;

          .download-btn {
            width: 100%;
            justify-content: center;
            padding: 0 16px;
            min-width: 180px;
            max-width: 280px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
